import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Divider, Card, CardContent, useTheme } from '@mui/material';

const Home = ({ language }) => {
  const [content, setContent] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/home/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.title}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.subtitle}
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          {content.p1_sections.map((section, index) => (
            <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
              <Typography variant="body1" component="p">
                {section}
              </Typography>
            </Box>
          ))}
        </Box>

        <Card sx={{ my: 4 }}>
          <CardContent>
            <Typography variant="h5" component="p" sx={{ textAlign: 'center', fontWeight: 'bold', py: 2 }}>
              {content.announcement}
            </Typography>
          </CardContent>
        </Card>

        <Box
          component="img"
          sx={{
            width: '100%',
            maxWidth: 1254,
            margin: '0 auto',
            display: 'block',
            borderRadius: '10px',
          }}
          alt="Placeholder"
          src="/images/out/oil_massage.jpg"
        />

        {content.p2_sections.map((section, index) => (
          <Box
            key={index}
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              {section.title}
            </Typography>
            <Typography variant="body1" component="p">
              {section.content}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default Home;
