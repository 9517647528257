import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from '@mui/material';

const BodyTreatments = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [priceContent, setPriceContent] = useState(null);
  const [prices, setPrices] = useState(null);
  const [rowsBodyTreatmentAfterSun, setRowsBodyTreatmentAfterSun] = useState(null);
  const [rowsBodyTreatmentMoisturizing, setRowsBodyTreatmentMoiisturizing] = useState(null);

  const [rowsScrubsBodyScrubs, setRowsScrubsBodyScrubs] = useState(null);
  const [rowsScrubsGreentea, setRowsScrubsGreentea] = useState(null);
  const [rowsScrubsDeadSea, setRowsScrubsScrubsDeadSea] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/body_treatment/${language}.json`);
      const priceContentResponse = await import(`../data/massage_prices/${language}.json`);
      const pricesResponse = await import(`../data/massage_prices/prices.json`);
      setPriceContent(priceContentResponse);
      setContent(response);
      setPrices(pricesResponse);
    };
    loadContent();
  }, [language]);

  useEffect(() => {
    if (prices) {
      const loadRows = () => {

        setRowsBodyTreatmentAfterSun([
          { duration: prices.body_treatment.after_sun.short.duration, price: prices.body_treatment.after_sun.short.price, discount: prices.body_treatment.after_sun.short.price * 0.8 }
        ]);

        setRowsBodyTreatmentMoiisturizing([
          { duration: prices.body_treatment.moisturizing.short.duration, price: prices.body_treatment.moisturizing.short.price, discount: prices.body_treatment.moisturizing.short.price * 0.8 }
        ]);

        setRowsScrubsBodyScrubs([
          { duration: prices.scrubs.body_scrubs.long.duration, price: prices.scrubs.body_scrubs.long.price, discount: prices.scrubs.body_scrubs.long.price * 0.8 }
        ])
        setRowsScrubsGreentea([
          { duration: prices.scrubs.greentea_scrubs.long.duration, price: prices.scrubs.greentea_scrubs.long.price, discount: prices.scrubs.greentea_scrubs.long.price * 0.8 }
        ])
        setRowsScrubsScrubsDeadSea([
          { duration: prices.scrubs.dead_sea_salt.long.duration, price: prices.scrubs.dead_sea_salt.long.price, discount: prices.scrubs.dead_sea_salt.long.price * 0.8 }
        ])

      };
      loadRows();
    }
  }, [prices]);

  if (!content || 
    !rowsBodyTreatmentAfterSun ||
    !rowsBodyTreatmentMoisturizing ||
    !rowsScrubsBodyScrubs ||
    !rowsScrubsGreentea ||
    !rowsScrubsDeadSea
  ) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>
        
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.description}</Typography>

        <Divider sx={{ my: 2 }} />
          
        <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.scrubs.title}</Typography>
        
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.scrubs.description}</Typography>

        <Box sx={{ textAlign: 'center', my: 4, p: 2, border: '1px solid #ddd', borderColor: theme.palette.divider, borderRadius: 2, backgroundColor: theme.palette.background.paper }}> 
          <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'center'}}>{content.scrubs.body_scrubs.title}</Typography>
          
          <Box
            component="img"
            sx={{ width: '100%', maxWidth: 1254, margin: '0 auto', display: 'block', borderRadius: '10px' }}
            alt="Facial Massage"
            src="/images/out/body_wrap.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.scrubs.body_scrubs.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="body treatment prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsScrubsBodyScrubs.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', my: 4, p: 2, border: '1px solid #ddd', borderColor: theme.palette.divider, borderRadius: 2, backgroundColor: theme.palette.background.paper }}> 
          <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'center'}}>{content.scrubs.greentea_scrubs.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%', maxWidth: 1254, margin: '0 auto', display: 'block', borderRadius: '10px' }}
            alt="Facial Massage"
            src="/images/out/greentea_wrap.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.scrubs.greentea_scrubs.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="body treatment prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsScrubsGreentea.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', my: 4, p: 2, border: '1px solid #ddd', borderColor: theme.palette.divider, borderRadius: 2, backgroundColor: theme.palette.background.paper }}> 
          <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'center'}}>{content.scrubs.dead_sea_salt.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.scrubs.dead_sea_salt.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="body treatment prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsScrubsDeadSea.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center'}}>{content.treatments.title}</Typography>

        <Box sx={{ textAlign: 'center', my: 4, p: 2, border: '1px solid #ddd', borderColor: theme.palette.divider, borderRadius: 2, backgroundColor: theme.palette.background.paper }}> 
          <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'center'}}>{content.treatments.after_sun.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.treatments.after_sun.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="body treatment prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsBodyTreatmentAfterSun.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', my: 4, p: 2, border: '1px solid #ddd', borderColor: theme.palette.divider, borderRadius: 2, backgroundColor: theme.palette.background.paper }}> 
          <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'center'}}>{content.treatments.moisturizing.title}</Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Facial Massage"
            src="/images/out/toning_face.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.treatments.moisturizing.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="body treatment prices table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsBodyTreatmentMoisturizing.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

      </Box>
    </Container>
  );
};

export default BodyTreatments;